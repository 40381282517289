
<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Add Event
      </h1>

      <div class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4">
       
      


















        

        <form method="post" @submit.prevent="addNew">
          <div class="flex flex-col">
            <div class="mb-6" id="message"></div>
            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="event"
              >
                Event
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="name"
                type="text"
                placeholder="Event"
                
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="event"
              >
              Url
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="url"
                type="text"
                placeholder=" Url"
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="event"
              >
              No. of Tickets
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="tickets"
                type="number"
                placeholder=" No. of Tickets"

              />
            </div>


            



            <div class="flex items-center justify-between">
              <button
                v-if="!loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Submit
              </button>
              <button
                v-if="loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                type="submit"
                disabled
              >
                Submitting...
              </button>
            </div>
          </div>
        </form>
      </div>
      <div id="recipients" class="">
          
        </div>
    </div>
  </main>
</template>

<script>

export default {

  name: "AddResale",
  data() {
    return {
      sidebarOpen: true,
      name: "",
      error: false,
      loading: false
    };
  },
  methods: {
    addNew() {
      this.error = false;

      this.url = document.getElementById("url").value;
      var url = this.url;
      var  nameevent;
      var totalno;
      this.no = document.getElementById("tickets").value;
      var no = this.no;
      if(url)
      {
        url = url.split("/");
        var text = url.indexOf("www.ticketmaster.com")+1;
       
        var val = url[text];
        nameevent = val.replaceAll("-", " ");
        console.log(nameevent);
      }
      else
      {
        nameevent = document.getElementById("name").value;
      }
      
      if(no)
      {
        totalno = no;
      }
      else
      {
        totalno = 1;
      } 




      
      this.loading = true;
      this.$axios
        .post(`https://spaceseats.io/api/resale/add/`, {
          name: nameevent,
          seats: totalno,
          selection: "standard",
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        },
        mode: 'no-cors',
      })
        .then(res => {
          this.total = res.data.data.length;
          

          this.loading = false;
          if (res.data.success) {
            // document.getElementById("message").innerHTML = `
            //   <span style="color:#3c763d;font-weight:bold">Operator added success</span>
            // `;
            // this.name = "";
            // var no =document.getElementById("tickets").value;
            document.getElementById("recipients").innerHTML = "";
            document.getElementById("message").innerHTML = "";
         
                var html = "<table id='tn-tickets' class='border-collapse w-full text-sm'><tr><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Type</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Seat</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Section</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Row</th><th class='p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell'>Offers</th></tr> <tbody id='ticketsList'>";
                for(var i=0; i < res.data.data.length; i++){
                  html += '<tr>';
                  html += '<td>'+res.data.data[i].selection+'</td>'+'<td>'+res.data.data[i].seats+'</td>'+'<td>'+res.data.data[i].section+'</td>'+'<td>'+res.data.data[i].row+'</td>'+'<td>'+res.data.data[i].offers+'</td>';
                  html += '</tr>';
                }
                html += "</tbody></table>";
                
                document.getElementById("recipients").innerHTML = html;

                





          } else {
            document.getElementById("message").innerHTML = `
              <span style="color:#a94442;font-weight:bold">${res.data.message}</span>
            `;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          document.getElementById("message").innerHTML = `
              <span style="color:#a94442;font-weight:bold">Something went wrong</span>
            `;
        });
      setTimeout(() => {
        document.getElementById("message").innerHTML = ``;
      }, 6000);
    }
  }
};

</script>
